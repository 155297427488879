import styled from '@emotion/styled';
import { Button, Grid2, InputAdornment, Paper, Theme } from '@mui/material';
import blueWhatsapp from 'assets/blueWhatsapp.svg';
import businessCard from 'assets/businessCard.svg';
import calendarIcon from 'assets/calendarIcon.svg';
import cancelledCheckExmp from 'assets/cancelledCheckExmp.svg';
import closeWhite from 'assets/closeWhite.svg';
import done from 'assets/done.svg';
import gift from 'assets/gift.svg';
import handsShake from 'assets/handsShake.svg';
import headerLogo from 'assets/headerLogo.svg';
import info from 'assets/info.svg';
import lock from 'assets/lock.svg';
import mursheCaseIcon from 'assets/mursheCase.svg';
import paturCaseIcon from 'assets/paturCase.svg';
import privacy from 'assets/privacy.svg';
import colors from 'src/styles/colors';

export const StyledStepFooterText = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  color: '#888',
  fontSize: '14px',
  padding: theme?.spacing(0, 4),
  alignSelf: 'center',
  textAlign: 'center',
  marginTop: '15px',
  whiteSpace: 'pre-line',
  [theme?.breakpoints.down('sm')]: {
    order: -1,
    marginBottom: theme?.spacing(3),
    padding: theme?.spacing(0, 'auto'),
  },
}));

export const StyledStepWrapper = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  margin: theme?.spacing(10, 'auto', 1.6),
  minHeight: theme?.spacing(64),
  maxWidth: theme?.spacing(76),
  [theme?.breakpoints.down('md')]: {
    minHeight: '75vw',
  },
}));

export const BlueWhatsappIcon = styled(blueWhatsapp)<{ theme?: Theme }>(({ theme }) => ({
  margin: '0 auto',
  display: 'flex',
  [theme?.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const HeaderContainer = styled('div')({
  display: 'flex',
});

export const SignUpLayoutBodyWrapper = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  padding: '50px 0',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  [theme?.breakpoints.down('md')]: {
    padding: '0px',
  },
}));

export const JonniLogo = styled(headerLogo)``;

export const HeaderLogo = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  margin: '0 auto',
  display: 'flex',
  [theme?.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const Done = styled(done)`
  margin: 0 -10px;
`;

export const StyledOverlay = styled('div')({
  position: 'absolute',
  background: '#ffffff9c',
  width: '100%',
  height: '100%',
  zIndex: 2,
  left: 0,
  top: 0,
});

export const LoaderWrapper = styled('div')({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const SignUpLayoutStepContainer = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  position: 'relative',
  width: '69%',
  maxWidth: '1000px',
  borderRadius: theme?.spacing(0.5),
  border: `1px solid ${colors.lightBorder}`,
  background: theme?.palette.background.paper,
  padding: theme?.spacing(10.6, 8, 6),
  minHeight: '850px',
  [theme?.breakpoints.down('md')]: {
    padding: theme?.spacing(6, 3),
    width: '100%',
    border: 'none',
    marginTop: '0px',
  },
  [theme?.breakpoints.down('sm')]: {
    padding: theme?.spacing(7, 3),
  },
}));

export const StepWrapper = styled('div')({
  margin: '0 auto',
});

export const SignUpLayoutBackgroundWrapper = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  width: '100%',
  background: '#fafafa',
  height: '100%',
  position: 'fixed',
  start: 0,
  display: 'flex',
  [theme?.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const SignUpLayoutBlueBackground = styled('div')({
  background: colors.primary,
  width: '45%',
});

export const CustomToastifyContainer = styled('div')({
  '.Toastify__toast': {
    flexDirection: 'row-reverse',
  },
  '@media screen and (max-width: 900px)': {
    '.Toastify__toast': {
      width: '92vw',
      marginBottom: '20px',
    },
  },
  '.Toastify__toast-icon svg': {
    fill: colors.error,
  },
  '.Toastify__close-button': {
    alignSelf: 'center',
    margin: '0px 15px',
    color: 'dimgray',
  },
  '.Toastify__close-button > svg': {
    fill: 'black',
  },
});

export const MonthlyIncomeStepContainer = styled('div')({
  marginTop: '56px',
});

export const MonthlyIncomeLoadingContainer = styled('div')({
  height: '100%',
  marginTop: '150px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const MonthlyIncomeItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: '24px',
});

export const VerificationTryDifferntNumberButton = styled('span')<{ theme?: Theme }>(({ theme }) => ({
  textDecoration: 'underline',
  cursor: 'pointer',
  marginTop: '10px',
}));

export const VerificationPhoneNumberText = styled('span')<{ theme?: Theme }>(({ theme }) => ({
  color: theme?.palette.text.secondary,
  fontWeight: 500,
  fontSize: '16px',
  marginInlineStart: theme?.spacing(1),
}));

export const VerificationResendCodeButton = styled(Button)<{ theme?: Theme }>(({ theme }) => ({
  color: colors.primary,
  fontSize: '16px',
  fontWeight: 500,
  display: 'flex',
  margin: '0 auto',
  textDecoration: 'underline',
  marginTop: theme?.spacing(10),
}));

export const WelcomeSubmitButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  margin: '50px auto',
});

export const WelcomeFooterText = styled(StyledStepFooterText)({
  marginBottom: '18px',
});

export const WelcomeContainer = styled('div')({
  marginTop: '-50px',
});

export const WelcomeLockIcon = styled(lock)({
  width: '27px',
  height: '40px',
  margin: '-28px',
});

export const WelcomePrivacyIcon = styled(privacy)({
  width: '22px',
  height: '40px',
  margin: '-28px',
});

export const WelcomeBusinessCardIcon = styled(businessCard)({
  width: '27px',
  height: '40px',
  margin: '-28px',
});

export const GiftDetailsIconContainer = styled('div')({
  textAlign: 'center',
  marginBottom: '15px',
  marginTop: '-35px',
});

export const GiftDetailsSVG = styled(gift)({
  width: '210px',
  height: '220px',
});

export const GiftDetailsDescription = styled('div')({
  textAlign: 'center',
  marginBottom: '15px',
  marginTop: '50px',
  fontSize: '28px',
  lineHeight: '35px',
  color: colors.darkBlue,
  fontWeight: 'bold',
});

export const DoneIconContainer = styled('div')({
  textAlign: 'center',
  marginBottom: '15px',
});

export const DoneHandsShakeIcon = styled(handsShake)({
  width: '175px',
  height: '175px',
});

export const DoneStepContainer = styled('div')({
  margin: '80px auto',
  height: '490px',
});

export const StyledWrapperStep = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  margin: theme?.spacing(10, 'auto', 1.6),
  minHeight: theme?.spacing(64),
  maxWidth: theme?.spacing(76),
  [theme?.breakpoints.down('md')]: {
    minHeight: '75vw',
  },
}));

export const StyledWrapperDoc = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  '& .react-transform-element': {
    width: '100%',
  },
}));

export const StyledButtonGroup = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  background: theme?.palette.background.paper,
  zIndex: 1,
  borderRadius: theme?.spacing(0.5),
  width: '100%',
  border: `1px solid ${theme?.palette.grey[200]}`,
}));

export const StyledImgViewer = styled('img')<{ theme?: Theme; rotate?: number }>(({ theme, rotate }) => ({
  width: '100%',
  borderRadius: theme?.spacing(0.5),
  transform: `rotate(${rotate}deg)`,
}));

export const ButtonSelectButton = styled(Button)<{ checked?: boolean; theme?: Theme }>(({ checked, theme }) => ({
  color: theme?.palette.text.secondary,
  fontSize: 16,
  fontWeight: 400,
  width: '100%',
  padding: '0 12px',
  border: checked ? `1px solid ${colors.primary}` : `1px solid ${colors.borderGray}`,
  backgroundColor: checked ? colors.lightGray : theme?.palette.background.paper,
  height: '48px',
  borderRadius: '4px',
  margin: '15px 12px',
  [theme?.breakpoints.down('sm')]: {
    margin: '15px 0px',
  },
}));

export const ButtonSelectContainer = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme?.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

export const StyledPaperViewer = styled(Paper)<{ theme?: Theme; padding: string }>(({ theme, padding }) => ({
  '&.MuiPaper-root': {
    backgroundColor: '#fafafa',
  },
  marginBottom: theme?.spacing(2),
  padding: padding,
}));

export const StyledForm = styled(Grid2)<{ theme?: Theme; smallMargin?: boolean }>(({ theme, smallMargin }) => ({
  marginTop: smallMargin ? theme?.spacing(-3) : '',
  marginInlineStart: '-3%',
  width: '106%',
  [theme?.breakpoints.down('md')]: {
    width: '100%',
    marginInlineStart: 0,
  },
}));

export const StyledFormItem = styled(Grid2)<{ theme?: Theme; paddingend?: boolean }>(({ theme }) => ({
  padding: theme?.spacing(0, '3%', 3, '3%'),
  boxSizing: 'border-box',
  [theme?.breakpoints.down('sm')]: {
    paddingInlineStart: 0,
    paddingInlineEnd: 0,
  },
}));

export const StyledSmallStartFormItem = styled(StyledFormItem)<{ theme?: Theme }>(({ theme }) => ({
  [theme?.breakpoints.down('sm')]: {
    padding: 0,
    paddingInlineEnd: '1.5%',
  },
}));

export const StyledSmallEndFormItem = styled(StyledFormItem)<{ theme?: Theme }>(({ theme }) => ({
  [theme?.breakpoints.down('sm')]: {
    padding: 0,
    paddingInlineStart: '1.5%',
  },
}));

export const StyledSmallFormItem = styled(StyledFormItem)<{ theme?: Theme }>(({ theme }) => ({
  [theme?.breakpoints.down('sm')]: {
    width: '103%',
    paddingInlineStart: '1.5%',
    paddingInlineEnd: '1.5%',
  },
}));

export const StyledLabel = styled('label')({
  alignSelf: 'flex-start',
  fontSize: '12px',
  fontWeight: 'bold',
  color: '#888',
  minHeight: '17px',
  marginBottom: '2px',
});

export const StyledErrorLabel = styled(StyledLabel)<{ theme?: Theme }>(({ theme }) => ({
  color: theme?.palette.error.main,
}));

export const StyledLink = styled('a')<{ theme?: Theme }>(({ theme }) => ({
  color: colors.primary,
  textDecoration: 'none',
}));

export const MursheCaseIcon = styled(mursheCaseIcon)`
  margin: 0 27px;
`;

export const PaturCaseIcon = styled(paturCaseIcon)`
  margin: 0 19px;
`;

export const AuthoritiesIdentificationDetailsDatePickerContainer = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  marginTop: theme?.spacing(-1.2),
  '& label': {
    marginBottom: theme?.spacing(-2.3),
  },
}));

export const BankDocumentUploadOrText = styled('div')({
  alignSelf: 'center',
  textAlign: 'center',
  color: colors.darkBlue,
  fontSize: '15px',
  fontWeight: 700,
  margin: '15px 0px',
});

export const BankDocumentUploadInfoIcon = styled(info)({
  width: '27px',
  height: '27px',
  margin: '13px 10px 0 0',
  cursor: 'pointer',
});

export const BankDocumentUploadFormItem = styled(StyledFormItem)({
  display: 'flex',
  padding: 0,
  justifyContent: 'center',
});

export const BusinessIndustriesSearchInput = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  width: '100%',
  marginBottom: theme?.spacing(4),
}));

export const BusinessIndustriesInputTitle = styled('div')({
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: '-12px',
  color: colors.lightBlueText,
});

export const CancelledCheckExmpIcon = styled(cancelledCheckExmp)({
  width: '100%',
  height: 'auto',
});

export const StyledInputFieldWrapper = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flexStart',
  '& .MuiFormHelperText-root': {
    display: 'flex',
    justifyContent: 'flexStart',
    marginInlineStart: 0,
    fontSize: '12px',
    fontWeight: 400,
  },
}));

export const StyledInputField = styled('div')<{ phone?: boolean }>(({ phone }) => ({
  width: phone ? '345px' : '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .MuiOutlinedInput-root': {
    color: colors.darkBlue,
    '& fieldset': {
      borderColor: '#C8DAFE',
    },
    '&:hover fieldset': {
      borderColor: '#80afff',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#80afff',
    },
  },
}));

export const StepHeaderContainer = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginBottom: theme?.spacing(7),
  whiteSpace: 'pre-line',
}));

export const StepHeaderTitle = styled('h3')({
  color: colors.darkBlue,
  alignSelf: 'center',
  lineHeight: '35px',
  marginBottom: '16px',
  fontSize: '28px',
  fontWeight: 'bold',
});

export const StepHeaderNoCodeText = styled('p')({
  fontSize: '16px',
  color: '#888',
  margin: 0,
  maxWidth: '450px',
  textAlign: 'center',
  lineHeight: '24px',
});

export const StepHeaderCodeText = styled('p')({
  fontSize: '16px',
  color: '#888',
  margin: 0,
  maxWidth: '350px',
  textAlign: 'center',
  lineHeight: '24px',
});

export const JonniMobileDatePickerCalendarIconInputAdornment = styled(InputAdornment)({
  margin: 0,
});

export const JonniMobileDatePickerCalendarIcon = styled(calendarIcon)``;

export const ApplicationDownloadButtonsIcon = styled('div')({
  height: '40px',
  width: '140px',
});

export const ApplicationDownloadButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const BusinessDetailsAddressInputInfoIcon = styled(info)({
  width: '23px',
  height: '23px',
  marginTop: '3px',
  cursor: 'pointer',
});

export const BusinessDetailsAddressInputInfoIconContainer = styled('div')({
  position: 'absolute',
  display: 'inline-flex',
  marginRight: '10px',
  marginTop: '0px',
});

export const BusinessDetailsAddressInputContainer = styled(StyledFormItem)<{ theme?: Theme }>(({ theme }) => ({
  marginTop: theme?.spacing(-0.7),
  '& svg': {
    display: 'none',
  },
  '& span': {
    display: 'none',
  },
}));

export const BusinessDetailsCardContainer = styled(Button)({
  padding: '16px 0',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '16px',
  marginBottom: '16px',
  width: '100%',
  backgroundColor: '#fafbff',
  height: '120px',
  borderRadius: '4px',
});

export const BusinessDetailsCardType = styled('div')({
  fontWeight: 'bold',
  fontSize: '14px',
  color: '#4357f2',
});

export const BusinessDetailsCardName = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  fontSize: '17px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '240px',
  [theme?.breakpoints.up('sm')]: {
    maxWidth: '380px',
  },
  [theme?.breakpoints.up('md')]: {
    maxWidth: '430px',
  },
}));

export const BusinessDetailsCardIdNumber = styled('div')({
  fontSize: '15px',
});

export const BusinessDetailsCardAddress = styled('div')({
  fontSize: '12px',
});

export const BusinessDetailsCardContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const BusinessDetailsCardWrapper = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme?.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

export const SelectableBusinessTypeInfoIcon = styled(info)({
  width: '25px',
  height: '25px',
  margin: '0px 20px',
  cursor: 'pointer',
});

export const SelectableBusinessTypeWrapper = styled('div')<{ icon?: boolean; theme?: Theme }>(({ icon, theme }) => ({
  display: 'flex',
  flexDirection: icon ? 'column' : 'row',
  alignItems: 'center',
  [theme?.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

export const SelectableBusinessTypeButton = styled(Button)<{ checked?: boolean }>(({ checked }) => ({
  padding: '16px 0',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '16px',
  marginBottom: '16px',
  width: '100%',
  border: checked ? '1px solid #4357f2' : '1px solid #c3c3c3',
  backgroundColor: checked ? '#fafbff' : '#fff',
  height: '96px',
  borderRadius: '4px',
}));

export const SelectableBusinessTypeInfoButton = styled('div')({
  alignItems: 'center',
  justifyContent: 'center',
  width: '80px',
  height: '80px',
  display: 'flex',
});

export const SelectableBusinessTypeTitle = styled('div')({
  fontWeight: 'bold',
  fontSize: '22px',
  color: '#162154',
});

export const SelectableBusinessTypeContent = styled('div')({
  width: '100%',
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
});

export const SelectableBusinessTypeInfoModalContent = styled('div')({
  textAlign: 'center',
});
